import React from 'react';

import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import SideDrawer from '../SideDrawer/SideDrawer';
// import CommunitiesList from '../CommunitiesList/CommunitiesList';
import MainNavigation from '../MainNavigation/MainNavigation';
import Editor from '../Editor/Editor';
// import PostEditor from '../PostEditor/PostEditor';
import './Toolbar.css';
import Switch from '../Switch/Switch';


  class Toolbar extends React.Component {  
    
    constructor(props) {
      super(props);
      this.state = {
        postEditor: false,
        sideDrawerOpen: false,
      };
    }
      

    callPostEditor = () => {
      const { postEditor } = this.state;
      this.setState( { postEditor : !postEditor } );
    }  
    drawerToggleClickHandler = () => {
      this.setState((prevState) => {
        return {sideDrawerOpen: !prevState.sideDrawerOpen};
      });
    };
  
    backdropClickHandler = () => {
      this.setState({sideDrawerOpen: false});
    };
  

    render() {
    var scrollPos = 0;
    this.scrollListener = window.addEventListener('scroll', () => {
      let el=document.getElementById('theHeader');
      if(el){

      // detects new state and compares it with the new one
      if (((document.body.getBoundingClientRect()).top > scrollPos))
        {el.classList.remove("hideHeader");}
      else
      {el.classList.add("hideHeader");}

    }
      // saves the new position for iteration.
      scrollPos = (document.body.getBoundingClientRect()).top;
    })
    
    let posttype = (this.props.filter === 'events') ? 'event' : 'status';

    let post = {posttype: posttype}




    let props = this.props; 
    let pageName = (this.props.showFollowing) ? 'JAlinkup' : this.props.profile.firstname || this.props.profile.username || 'JAlinkup' ;
    // let pageName = this.props.profile.firstname || this.props.profile.username || 'WorldWide';
    let filterDisplay = (pageName==='JAlinkup' & this.props.filter ==='feed')? 'Uniting Jamaicans, link by link' : this.props.filter;
    // console.log('profile...... ',this.props.profile);
  return(
    <React.Fragment>
      <header id="theHeader" className={' toolbar '+props.headerCls}>
        <div className="theNavWrap">
          <nav className="toolbar__navigation">
            <div className="logo__wrap hXXideInMobile">
              <div className="toolbar__logo">
                <a className="toolbar__logo__a" href="/">
                  <img className="toolbar__logo__img" src="http://jalinkup.com/user/12957e806beb4048/avatar" alt="JAlinkup.com" />       
                </a>
              </div>
            </div>
            <div className={` toolbarMid ${pageName}`}>
            {/* In the future, make this a dropdown and search */}

            {(this.props.loggedIn && !this.props.profile.username)?
              <React.Fragment>
                <div className="aw-Header__switch">
                  <Switch label1 = {this.props.profile.username || 'WorldWide'} handleFeedChange={this.props.handleFeedChange} showFollowing={this.props.showFollowing} click={this.props.feedHandler} />
                </div>
              <a href={`/${this.props.profile.username || ''}`} className={` name__page hide${this.props.loggedIn}-sm`}>{pageName} </a>
             </React.Fragment>

            :
            <a href={`/${this.props.profile.username || ''}`} className={` name__page`}>{pageName} </a>
            }
              {/* <a href={`/${this.props.profile.username || ''}`} className={` name__page`}>{pageName} </a> */}
              <div className="filter__page"> 
                <span className="hideInMobile" style={{padding:'0 5px'}}></span> 
                {filterDisplay}
              </div>
            </div>
            <div className="toolbarRight pull-right">
              <MainNavigation 
                profile={this.props.profile} 
                loggedIn = {this.props.loggedIn} 
                showFollowing={this.props.showFollowing} 
                handleFeedChange={props.handleFeedChange} 
                callPostEditor={this.callPostEditor} 
              />
              <div className="toolbar__toggle-button xxmobile__only">
                <DrawerToggleButton click={this.drawerToggleClickHandler} />
              </div>        
            </div>
          </nav>
        </div>
      </header>

      {/* Plus sign with Create New Post */}
      <div className="call__createPost btn" onClick={this.callPostEditor}>
      {/* <a className="call__createPost btn" href={`/submit?page=${this.props.profile.username || ''}`} target="_blank" rel="noopener noreferrer"> */}
        <svg className="pull-left" width="50px" height="50px">
          <line x1="0" y1="15" x2="30" y2="15" stroke="yellow" strokeWidth="5"></line>    
          <line x1="15" y1="0" x2="15" y2="30" stroke="yellow" strokeWidth="5"></line>    
        </svg> 
        <div className="desktop__post">
          Create New Post
        </div>   
      {/* </a> */}
      </div>

      {/* <div className="main_header_strip">
        <div className="loginWrapper strip__scroller">
          <CommunitiesList profile={this.props.profile} />
        </div>
      </div> */}

      {(this.state.postEditor) &&  
        <Editor post={post} close={this.callPostEditor} profile={this.props.profile} user={this.props.currentUser} newPost={true} />
        // <PostEditor toggleMenu={this.callPostEditor} newPost={true} />
      }            
      <SideDrawer
        loggedIn = {this.props.loggedIn}
        show={this.state.sideDrawerOpen} 
        backdropClickHandler={this.backdropClickHandler}
      />
    </React.Fragment>
  )
}
};
export default Toolbar;