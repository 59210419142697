// import React, { Component } from 'react';
import React from 'react';
import './MainEvents.css';

class MainEvents extends React.Component {
    constructor(props) {
      super(props);
      this.IMAGE_PARTS = 1;
      this.changeTO = null; 
      this.AUTOCHANGE_TIME = 5000;
      this.state = { 
          activeSlide: -1, 
          prevSlide: -1, 
          sliderReady: false,
          slides: [],
          postsBy:this.props.postsBy,
          showFollowing: this.props.showFollowing,
      };
    }

    componentWillReceiveProps(props) {
      this.setState({
        showFollowing: props.showFollowing,
        postsBy: props.postsBy,
        slides: [],
      }, 
      () => {
        this.loadPosts();
      });
    }
    loadPosts = () => {
      let postsBy = this.props.postsBy;
      const per = 4;
      const page = 0;
      // const url = `http://jalinkup.com/api/api/post/read.php?type=event&per=${per}&page=${page}&mention=${postsBy.replace(/\W/g, '')}`;
      const url = `http://jalinkup.com/api/api/post/event.php?type=event&per=${per}&page=${page}&following=${postsBy}`;
      // console.log('url... ',url);
      fetch(url)
      .then(response => response.json())
      .then((posts) => {
        this.setState({
          slides: [...posts.data],
        });
      });
    };  

    componentWillUnmount() {
      window.clearTimeout(this.changeTO);
    }
    
    componentDidMount() {
      this.runAutochangeTO();
      setTimeout(() => {
        this.setState({ activeSlide: 0, sliderReady: true });
      }, 0);
    }
    
    runAutochangeTO() {
      this.changeTO = setTimeout(() => {
        this.changeSlides(1);
        this.runAutochangeTO();
      }, this.AUTOCHANGE_TIME);
    }
    
    changeSlides(change) {
      window.clearTimeout(this.changeTO);
      const { length } = this.state.slides;
      const prevSlide = this.state.activeSlide;
      let activeSlide = prevSlide + change;
      if (activeSlide < 0) activeSlide = length - 1;
      if (activeSlide >= length) activeSlide = 0;
      this.setState({ activeSlide, prevSlide });
    }
    
    render() {
      const { activeSlide, prevSlide, sliderReady } = this.state;
      var theCardWrap = sliderReady ? 's--ready' : '';
      const { length } = this.state.slides;


      let message = (this.state.slides[0]) ? this.state.slides[0].message : '' ;
      if(message) return null;
    //   replace null with profile card 

      let controls = (length > 1) ? 
        <React.Fragment>
          <div className="slider__control" onClick={() => this.changeSlides(-1)} />
          <div className="slider__control slider__control--right" onClick={() => this.changeSlides(1)} />
        </React.Fragment>
      : '';

    return (

        <div className={'slider '+theCardWrap}>
          <div className="slider__slides">
            {this.state.slides.map((slide, index) => {
                
            let theActiveCard = (activeSlide === index) ? ' s--active ' : '';
            let theCard = (prevSlide === index)? ' s--prev ' : '';

            {var location = slide.city || slide.state || slide.country  || slide.username}
            let date = (slide.evdate) ? window.formatDate(slide.evdate,'long','home'):'';

            return(
              <div className={'slider__slide '+ theCard + theActiveCard} key={slide.pid}>
                <div className="slider__slide-content">
                    {controls}
                    <h3 className="slider__slide-subheading">
                      <div>
                      <a target="_blank" rel="noopener noreferrer" className="slider__slide-title" href={'http://jalinkup.com/post/'+ slide.pid}>
                        {slide.otitle}
                      </a>
                      </div>
                      <div className="the-slider__slide-date">
                        -<span className="slider__slide-date" dangerouslySetInnerHTML={{ __html: date }} ></span>-
                      </div>
                      <a target="_blank" rel="noopener noreferrer" className="slider__slide-location" href={`/${location}/events`}>
                          @{location}
                      </a>
                      {/* <a target="_blank" rel="noopener noreferrer" className="slider__slide-readmore" href={'http://jalinkup.com/post/'+ slide.pid}>
                          details
                      </a> */}
                    </h3>
                    {/* <a target="_blank" rel="noopener noreferrer" className="slider__slide-location" href={'/'+ location}>
                        @{location}
                    </a>
                    <a target="_blank" rel="noopener noreferrer" className="slider__slide-readmore" href={'http://jalinkup.com/post/'+ slide.pid}>
                        Go to event
                    </a> */}
                </div>
                <div className="slider__slide-parts">
                  {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                    <div style={{width:`${100/this.IMAGE_PARTS}%`}} className="slider__slide-part" key={i}>
                      <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.photourl})` }} />
                    </div>
                  ))}
                </div>
              </div>
            )})}
          </div>
        </div>
      );
    }
  }

  export default MainEvents;