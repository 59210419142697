import React from 'react';
import './Overlay.css';

function Overlay(props) {
    return (
      <React.Fragment>
      {props.backgroundClose &&
      <div id={'overlay__bg'} className="overlay__bg" onClick={props.close}> </div>
      }
      {!props.backgroundClose &&
      <div id={'overlay__bg'} className="overlay__bg"> </div>
      }        
      <div id={'overlay__container'} className={'overlay__container overlay__container-' + props.theme}>
      {/* <div id={'overlay__wrap'} className={'overlay__wrap overlay__wrap-' + props.theme}> */}
      {/* <div id={'awCreateBoxFixed'} className={'awCreateBoxFixed xyCenter awCreateBoxFixed-' + props.theme + ' dialog__wrap dialog__wrap-' + props.theme}> */}
        {/* <div className="awCreateBoxWrap"> */}
          {/* <div className="awCreateBox">  */}
            {/* <div className ={'dialog__wrap dialog__wrap-' + props.theme}> */}
                {props.header &&
                    props.header
                }
                {props.body &&
                    props.body
                }
                {props.footer &&
                    props.footer
                }
                {props.children}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
        {/* </div>     */}
        </div>    
      </React.Fragment>

    );
}
export default Overlay; 