import React from 'react';
// import PropTypes from 'prop-types';

const CommunitiesListCard = ({profile,image,filter,faClass}) => {

    console.log('faClass... ',faClass);
    let {username,avatarurl,firstname} = profile;
    var profileImg = (image && avatarurl ) ?
        (avatarurl.includes("user/")) ?
            <div className="scrolling-img-wrap">
                <img className="scrolling-img" src={`http://jalinkup.com/${avatarurl}`} alt={username} />
            </div>
        :
            <div className="scrolling-img-wrap">
                <img className="scrolling-img" src={`${avatarurl}`} alt={username} />
            </div>
    :
        (image) ?
            (faClass)?
            <div className="scrolling-img-wrap">
                <i className= {`fa ${faClass}`}></i>
            </div>
            :
            <div className="scrolling-img-wrap">
                <i className=" fa fa-map-marker"></i>
            </div>
        :
        ''
    ;

    return (
        <a className="scrolling-card" href={`/${username}/${filter}`}>
            {profileImg}            
            <div className="scrolling-name">{firstname || '@'+username}</div>
        </a>
    )
}

// Card.propTypes = {
//     property: PropTypes.object.isRequired
// }

export default CommunitiesListCard;