import React from 'react';

function Svg(props){
    let {size,color} = props;
    
    return(
    <svg style={{height:size+"px"}} viewBox={`0 0 ${size} ${size}`} version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1={size} 
                x2={size} y2="1" 
                stroke={color || "green"}
                strokeWidth="2"/>
            <line x1="1" y1="1" 
                x2={size} y2={size} 
                stroke={color || "green"} 
                strokeWidth="2"/>
        </svg>        
    );
}
export default Svg;