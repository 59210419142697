import React from 'react';

import './SideDrawer.css';
import Login from '../Login/Login';
import Backdrop from '../Backdrop/Backdrop';


// const sideDrawer = props => {

  class SideDrawer extends React.Component {  
    
    constructor(props) {
      super(props);
      this.state = {
        postEditor: false,
      };
    }
    render() {
      var backdrop; 
      let mainCls = ''; 
      let headerCls = '';

  let drawerClasses = 'side-drawer right';
  if (this.props.show) {
    drawerClasses = 'side-drawer right open';
    backdrop = <Backdrop click={this.props.backdropClickHandler} />;
  }

  return (
    <React.Fragment>
    {backdrop}
    <nav className={drawerClasses}>
      {/* <div>
        {(!this.props.loggedIn) && 
          <Login />
        }
      </div> */}

      <ul>

        <li>
          <a href="/?filter=events">Events</a>
        </li>
        <li>
          <a href="/?filter=communities">Communities</a>
        </li>
        <li>
          <a href="/?filter=people">Users</a>
        </li>
      </ul>
    </nav>
    {/* {backdrop} */}
</React.Fragment>
  )
      }
};

export default SideDrawer;
