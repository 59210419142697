import React from 'react';
import Login from '../Login/Login';
import ShareVia from '../ShareVia/ShareVia';

import './ProfileCard.css';
// import CommunitiesList from '../CommunitiesList/CommunitiesList';

// function ProfileCard(props){
class ProfileCard extends React.Component{

    constructor(props){
        super(props);
        this.state = {
          callLogin:false,
          showShare:false,
        }
    }
    toggleShareVia = () => {
        let{showShare} = this.state;
        console.log('share called...');
        this.setState( 
          { showShare : !showShare },
        );
      }
  
    callLogin = () => {
        const { callLogin } = this.state;
        this.setState( { callLogin : !callLogin } );
    }  
  
    followRequest = (action,followee,follower,isLoggedIn,callLogin) =>{
        if(!isLoggedIn) {
            console.log('notLoggenIn...');
            return 'notLoggedIn'
        }

        let inputData = [
            {action: action, followee:followee,follwer:follower},
        ];

        // return null;
        let url = `http://localhost:81/jalinkup/api/api/users/follow.php`;

        // let url = `http://jalinkup.com/api/api/users/follow.php`;
        // console.log('continue request inputData...',inputData,'url.. ',url);

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(inputData)
        })
        .then(response => response.json())
        .then((res) => {

            // if(res=''){
            // //setState to update btn
            // }
            // else if(res=''){
            // //setState to update btn
            // }

        //   this.setState({
        //     allEvents: [...posts.data],
        //   });
        });
    }
    loadPosts = () => {
        let postsBy = this.props.postsBy;
        const per = 4;
        const page = 0;
        const url = `http://jalinkup.com/api/api/post/event.php?type=event&per=${per}&page=${page}&mention=${postsBy.replace(/\W/g, '')}`;
        // const url = `http://jalinkup.com/api/api/post/read.php?type=event&per=${per}&page=${page}&following=${postsBy}`;
        fetch(url)
        .then(response => response.json())
        .then((posts) => {
        this.daNewEvents = [...posts.data]
        this.setState({
            allEvents: [...posts.data],
        });
        });
    }; 


    render(){
        if(!this.props.profile.juid){return null;}
        let {filter} = this.props;
        let {jadmins,juid,firstname,username,userlevel,pagetype,avatarurl,website,topmedia,ulocation,numfollowing,numfollowers,jubio} = this.props.profile;
        // if (filter !== 'feed'){
        //     return(
        //         <div className="profileCard__Wrapper clearfix">
        //             <div className="pad0 profileCard__main-info flex-c">
        //                 <h1 className="profileCard__name">{firstname} <small>@{username}</small></h1>
        //                 {/* <ProfileLocation ulocation={ulocation} />
        //                 <ConnectionSummary 
        //                     numFollowers={numfollowers} 
        //                     numFollowing={numfollowing}
        //                 /> */}
        //             </div>                

        //         <ProfileOptions 
        //             username={username} 
        //             userlevel={userlevel} 
        //             pagetype={pagetype} 
        //         />
        //         </div>
        //     );
        // }
        

        let {loggedIn,following} = (this.props.currentUser) ? this.props.currentUser : '';
        let curJuid = (this.props.currentUser) ? this.props.currentUser.juid : '';

        let {callLogin} = this.state;

        if(avatarurl){
            avatarurl = (avatarurl.includes("user/")) ? 'http://jalinkup.com/'+avatarurl : avatarurl;
            var profileImg = (avatarurl) ?
            <div className="profileCard__img-wrap">
                <img className="profileCard__img" src={avatarurl} alt={username} />
            </div>
            :
            <div className="profileCard__img-wrap">
                <i className=" fa fa-map-marker"></i>
            </div>
            ;
        }
        
        let isFollowing = (following) ? following.includes(juid) : false;
        let isPageAdmin = (jadmins && curJuid) ? jadmins.includes(curJuid) : false;
        let isPageOwner = (curJuid === juid) ? true : false;

        let showFollowHtml = (isPageOwner) ?
            <React.Fragment>
            <div className="btn btn-default"> Alerts</div>
            </React.Fragment>
        :  
            <React.Fragment>
            {(isFollowing) ?  
                <div className="btn btn-default"> Following</div>
            :  
                <React.Fragment>
                {(loggedIn)?
                    <div className="btn btn-success" onClick={this.followRequest('follow','follower','follower',loggedIn)}> Follow</div>
                :
                    <div onClick={this.callLogin} className="btn btn-success">Follow</div>
                }
                </React.Fragment>
            }
            </React.Fragment>
        ;
        let pageActionHtml = (isPageAdmin || isPageOwner) ? 
            <React.Fragment>
            {showFollowHtml}
            <a href={`/${username}/edit`} className="btn btn-default"> Edit</a>
            </React.Fragment>
        :  
            <React.Fragment>
            {showFollowHtml}
            </React.Fragment>
        ;
        return(
            <React.Fragment>
            <div className={`profileCard__Wrapper clearfix ${(filter!== 'feed') ? 'miniPrifile' : ''}`}>
                <div className="pad0 col-sm-7 col-xs-12 profileCard__main flex-r">
                    {profileImg}
                    <div className="pad0 profileCard__main-info flex-c">
                        <h1 className="profileCard__name">{firstname} <small>@{username}</small></h1>
                        <ProfileLocation ulocation={ulocation} />
                        <ConnectionSummary 
                            numFollowers={numfollowers} 
                            numFollowing={numfollowing}
                        />
                    </div>                
                </div>
                <div className="profileCard__actionWrap pad0 col-sm-5 col-xs-12 flex-c">
                    <ProfileSocials 
                        website={website} 
                        topmedia={topmedia} 
                    />
                    {(jubio) &&
                    <div className="profileBio">
                        {jubio}
                    </div>
                    }

                    <div className="profileCard__action flex-r">
                        {(this.state.showShare) &&
                        <ShareVia urlToShare={'http://jalinkup.com/link/'+juid} profile={this.props.profile} toggleShareVia={this.toggleShareVia}/> 
                        }                    
                        {pageActionHtml}
                        <div onClick={this.toggleShareVia} className="p-btn btn btn-default"> Share</div>
                    </div>
                    {/* {(jubio) &&
                    <div className="profileBio">
                        {jubio}
                    </div>
                    } */}
                </div>
                {(callLogin) &&
                <Login toggleMenu={this.callLogin}/>
                }

                <ProfileOptions 
                    username={username} 
                    userlevel={userlevel} 
                    pagetype={pagetype} 
                />
            </div>
        </React.Fragment>
        );
    }
}
export default ProfileCard;

function ProfileOptions(props){
    let {username,userlevel,pagetype} = props;
    return(
        <div className="col-xs-12 pad0 profileFeedFilter">
            <a className="btn" href={`/${username}/feed`}>Activities</a>
            <a className="btn" href={`/${username}/events`}>Events</a>
            {(userlevel === 'l') &&
                <React.Fragment>
                <a className="btn" href={`/${username}/people`}>People</a>
                {(pagetype !=='city') &&
                    <React.Fragment>
                    <a className="btn" href={`/${username}/communities`}>Communities</a>
                    </React.Fragment>
                }
                </React.Fragment>
            }
        </div>
    );
}

      function ConnectionSummary(props){
        let {numFollowing,numFollowers} = props;
        let numFollowersName = (numFollowers === 1) ? 'Follower' :'Followers';
        let numFollowingHtml = (numFollowing > 0) ?
            <React.Fragment>
                <span> Following <strong>{numFollowing}</strong></span>
            </React.Fragment>
        :'';
        let numFollowersHtml = (numFollowers > 0) ?
            <React.Fragment>
                <span> {numFollowersName} <strong>{numFollowers}</strong></span>
            </React.Fragment>
        :'';
        let devider = (numFollowersHtml && numFollowingHtml) ?
            <span> • </span>
        :'';
        return(
            <React.Fragment>
                <div>                  
                    {numFollowersHtml}
                    {devider}
                    {numFollowingHtml}
                </div>
            </React.Fragment>

        );
    }

    function SocialRow(props) {
        const {name,url,cls} = props;
        const clsName = cls ? cls.toLowerCase() : name.toLowerCase();
        return (
            <span className={'btn btn-xs padding-a0 cls-smPages '}>
                <a className={clsName+'Color'} href={'/ext?url=' + url} target="_blank" rel="noopener noreferrer">
                    {/* <i className={'fa fa-'+clsName}></i> */}
                    {name}
                </a>
            </span>
        );
    }


    function ProfileSocials(props) {
        let {website,topmedia} = props;
        if(!website && !topmedia) {
            return null;
        };
        var socialArray = [];

        if (website){
            socialArray.push({name: 'Website', cls: 'globe', url: website});
        }
    
        if (topmedia){
            let locationPairs = topmedia.split("|j|");
            let xLimit = locationPairs.length;
            for (let x = 0; x < (xLimit); x++) {
                var ulocPair = locationPairs[x];
                var ulocPairSplit = ulocPair.split(",",2);
        
                var ulocName = ulocPairSplit[0];
                var url = ulocPairSplit[1]; 
        
                socialArray.push({name: ulocName, cls: ulocName, url: url});
            }
        }
    
        return (
            <React.Fragment>
                <div className="profileCard__social flex-r">
                <i className=" fa fa-link btn-xs"> </i>
                {
                socialArray.map((social, index) => 
                    <SocialRow
                        name={social.name}
                        url={social.url}
                        cls={social.cls}
                        key={social.name}
                        />
                )
                }
                </div>
            </React.Fragment>
        );
    }


    function ProfileLocation(props) {
        let {ulocation} = props;
        if(!ulocation) {
            return null;
        };

        let locationPairs = ulocation.split("|j|",3);
        let xLimit = locationPairs.length;
        var location = [];
        for (let x = 0; x < (xLimit); x++) {
            var ulocPair = locationPairs[x];
            var ulocPairSplit = ulocPair.split(",",2);

            var ulocID = ulocPairSplit[0]; 
            var ulocName = ulocPairSplit[1];

            location.push({name: ulocName, juid: ulocID, url: '/'+ulocID});
        }
        location = location.reverse();
    
        return (
            <React.Fragment>
            <div>
                {/* <i className=" fa fa-map-marker"></i> */}
                {
                    location.map((location, index) => 
                        <LocationRow 
                            name={location.name} 
                            url={location.url} 
                            cls={location.cls} 
                            key={location.name} 
                        />
                    )
                }
            </div>
        </React.Fragment>
);
    }

    function LocationRow(props) {
        const {name,url,cls} = props;
        const clsName = cls ? cls.toLowerCase() : name.toLowerCase();
        return (
            <a className={clsName+'Color profileCard__locName'} href={url}>
                {name}
            </a>
        );
    }