import React from 'react';
// import PropTypes from 'prop-types';

const MinorEventsCard = ({activeIndex,indexData,property}) => {
    const {evdate, otitle,pid,username,firstname, photourl, city, state, country} = property;
    var theCard = (activeIndex === indexData)? 'active-slide-card' : '';
    return (
        <a href={`http://jalinkup.com/post/${pid}`} target="_blank" rel="noopener noreferrer" id={`card-${indexData}`} className={` cardW card ${theCard}`}>
            <div className="card-img-wrap" style={{background: 'url(' + photourl + ') center center / cover no-repeat rgb(35, 41, 55)',height:'140px',width:'100%'}}>
                {/* loads image */}
            </div>
            <div className="card-details">
                <div className="aw-titleWrap__card">
                    {(evdate) &&
                        <div dangerouslySetInnerHTML={{ __html: window.formatDate(evdate,'short')}} className="aw-postEvDate titleDate"></div>
                    }
                    <div>
                        <small className="aw-card__location">{city || state || country || firstname || '@'+username} <br /></small>
                    {otitle &&
                    <React.Fragment>
                        {otitle}
                    </React.Fragment>
                    }
                    </div>
                </div>
                {/* <div>{date}</div> */}
                {/* <div>{evdate}</div> */}
                {/* <span className="index">{date}</span>
                <p className="location">
                    {city || state || country || firstname || '@'+username}<br />
                    
                </p> */}
            </div>
        </a>
    )
}

// Card.propTypes = {
//     property: PropTypes.object.isRequired
// }

export default MinorEventsCard;