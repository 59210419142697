import React from 'react';
import CommunitiesList from '../CommunitiesList/CommunitiesList';
// import ProfileOptions from '../ProfileCard/ProfileCard';

function HeaderStrip(props){
  return(
      <div className="main_header_strip">
      {/* add page header main here */}
        {/* <ProfileOptions 
            username={props.profile.username} 
            userlevel={props.profile.userlevel} 
            pagetype={props.profile.pagetype} 
        /> */}

      <div className="loginWrapper strip__scroller">
        {/* {<CommunityList showFollowing = {showFollowing} region={currentPage.juid} />} */}
        {/* <div style={{textAlign:'center',padding:'10px 5px'}}>
          Site maintenance underway - <a href="/jalinkup" target="_blank" rel="noopener noreferrer">read more</a>
        </div> */}
        <CommunitiesList
          profile={props.profile} 
          postsBy={props.postsBy}
          filter={props.filter}
        />
      </div>
    </div>
  );
}
export default HeaderStrip;