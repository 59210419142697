import React, { Component } from 'react';
import './CommunitiesList.css';
import CommunitiesListCard from './CommunitiesListCard';
class CommunitiesList extends Component {

  constructor(props){
    super(props);
    this.state = {
      profiles: [],
      per:10,
      page:0,
    }
  }

  loadCommunities = () => {
    let { per, page, profiles } = this.state;
    // let postsBy = this.props.postsBy;
    let pagetype =  this.props.profile ? this.props.profile.pagetype : '';
    let juid =  this.props.profile ? this.props.profile.juid : '';
    if(juid==='undefined' || pagetype==='undefined'){ return};
    // const per = 4;
    // const page = 0;
    // const url = `http://localhost:81/jalinkup/api/api/users/communities.php?communityJuid=${juid}&communityType=${pagetype}`;
    
    //ORDER THIS BY lastposted//

    // let followingCSV = this.props.following;
    // const url = `http://jalinkup.com/api/api/users/communities.php?communityJuid=${juid}&communityType=${pagetype}`;


    let comminitiesBy = this.props.postsBy;
    let endpoint = (comminitiesBy.length > 20) ? `following=${comminitiesBy}` : `profileid=${comminitiesBy.replace(/\W/g, '')}` ;
    const url = `http://jalinkup.com/api/api/users/communities.php?communityType=${pagetype}&page=${page}&per=${per}&${endpoint}`;

    fetch(url)
    .then(response => response.json())
    .then((results) => {
      this.totalResults = results.total;
      // this.totalResults = json.total;
      if(results.total > 0) {
        this.setState({
          profiles: [...profiles,...results.data],
          // scrolling: false,
          // totalPages: page + 2,
        })
      }
      else{
        this.setState({
          // scrolling: false,
          totalPages:results.total,
        })
      } 
    });
  }; 
componentWillReceiveProps(props) {

  this.setState({
    profiles: [],
  }, 
  () => {
    this.loadCommunities();
  });
}
loadData = () => {
  this.setState(prevState => ({
    page: prevState.page+1,
    loading: true,
  }), this.loadCommunities)
}


scrollSlider = () =>{

}

  render() {
    var { per} = this.state
    // var { per,page } = this.state

    let loadStatus = (this.totalResults === 0) ?
      ''
    :
      (this.totalResults === per)?
        <LoadProfileStatus totalResults={this.totalResults} text={`Load more`} loadData={this.loadData}/>             
      :
        ''
    ;
    
    let {filter} = this.props;
    var {profiles} = this.state;
    return (
      <React.Fragment>
      <div className="scrolling-wrap">
        <div className="scrollControls">
            <a className="prev" onClick={this.scrollSlider(-1)}>&#10094;</a>
                Slide countries
            <a className="next" onClick={this.scrollSlider(1)}>&#10095;</a>
        </div>
        <div className="scrolling-wrapper-flexbox">
        { this.state.profiles.length > 0 &&
          profiles.map((profile, index) => (
            <CommunitiesListCard image={true} key={profile.username} profile={profile} filter={filter} />
          ))
        }
        {loadStatus}
        </div>
      </div>
      </React.Fragment>
    );
  }
}
export default CommunitiesList;

function LoadProfileStatus(props){
  let {text,totalResults} = props;
  return(
    <div className="scrolling-card" onClick={props.loadData}>
      <div className={`scrolling-img-wrap loadMore Cls${totalResults}`}>
      </div>
      <div className="scrolling-name">{text}</div>
    </div>
  )
}