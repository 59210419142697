import React from 'react';
import './Feed.css';

export default class PostMedia extends React.Component{
    // renderSwitch(post) {
    //   switch(post.vidsrc) {
    //     case null:
    //     case '':            
    //     case 'undefined':            
    //     const cssClass = post.otitle ? post.posttype : 'photo'; //setcssClass= photo if no title
    //     return ( 
    //       <div className=" aw-hasMediaWrap clearfix">
    //         <div className={'aw-'+cssClass+'ImageWrap  mediaImageWrap'}>
    //           <img onError={(e) => {e.target.parentNode.style = 'display:none;';}} className={'aw-align aw-align'+cssClass} src={post.photourl} alt="pic" />                
    //         </div>
    //         <MediaDetails cssClass={cssClass} post={post}/>
    //       </div>
    //     )
    //     ;
    //     break;            
    //     default:
    //       return (
    //         <div className="aw-hasMediaWrap">
    //           <VideoPlayerWrap cssClass={'video'} post={post} />
    //           <MediaDetails cssClass={'video'} post={post}/>
    //         </div>
    //       )
    //     ;
    //   }
    // }    

    render(){
      let {post} = this.props;
      const cssClass = post.vidsrc ? 
        post.otitle ? 
          post.posttype 
        : 'photo' 
      : 
        post.posttype
      ; //setcssClass= photo if iframe src and no title
      // const cssClass = post.otitle ? post.posttype : 'photo'; //setcssClass= photo if no title
      // console.log('iframe src.... ',post.vidsrc)
      return (
        <React.Fragment>
        {post.vidsrc &&
          <div className="aw-hasMediaWrap">
            <VideoPlayerWrap cssClass={'video'} post={post} />
            <MediaDetails cssClass={'video'} post={post}/>
          </div>
        }
        {!post.vidsrc &&
          <div className=" aw-hasMediaWrap clearfix">
            <div className={'aw-'+cssClass+'ImageWrap  mediaImageWrap'}>
              <img onError={(e) => {e.target.parentNode.style = 'display:none;';}} className={'aw-align aw-align'+cssClass} src={post.photourl} alt="pic" />                
            </div>
            <MediaDetails cssClass={cssClass} post={post}/>
          </div>
        }
        {/* {this.renderSwitch(this.props.post)} */}
        </React.Fragment>
      )
    }
  }
  
  class VideoPlayerWrap extends React.Component{
    goPlay = () => {
      let iframe = document.getElementById('ytplay_'+this.props.post.pid);
      let playBtn = document.getElementById('play_icons_'+this.props.post.pid);
      iframe.src=this.props.post.vidsrc;  iframe.style= 'display:block;';
      playBtn.style= 'display:none;';
    }
    render(){
    let pid = this.props.post.pid;
      return (
        <React.Fragment>
        <div className="aw-videoPlayerWrap" onClick={this.goPlay}>
          <div id={'yt_'+pid} className="aw-videoWrapper">
            <div id={'ytimg_'+pid} className="aw-videoImageWrap">
              <img className="alignV" src={this.props.post.photourl} alt="pic" />                
            </div>
            <div id={'play_icons_'+pid} className="playBtn">
              <div className="arrow-right"></div>
            </div>
            <iframe id={'ytplay_'+pid} className="mediaIframe" src={this.props.post.Xvidsrc} frameBorder="0" scrolling="no" allowFullScreen="">
              
            </iframe>                
          </div>
        </div>            
        </React.Fragment>
      )
    }
  }
  
  class MediaDetails extends React.Component{
    render(){
      if (!this.props.post.otitle && !this.props.post.abstract && !this.props.post.evdate) {
        return null;
      }
      const postUrl = this.props.post.adurl ? this.props.post.adurl : this.props.post.linkurl;
      const titleHtml = postUrl ? 
        <a className="aw-titleWrap__title" href={''+postUrl+''} target="_blank" rel="noopener noreferrer">
          {this.props.post.otitle}
        </a> 
        :
        <span className="aw-titleWrap__title">
          {this.props.post.otitle}
        </span> 
      ;                
      return (
        <React.Fragment>
        <div id={'detail'+this.props.post.pid} className={'aw-mediaDetails aw-'+this.props.cssClass+'MediaDetails'}>
          <div className="aw-titleWrap">
            {titleHtml}
            {(this.props.post.evdate) &&
              <div dangerouslySetInnerHTML={{ __html: window.formatDate(this.props.post.evdate,'short') }} className="aw-postEvDate titleDate">
              </div>
            }
            {/* {titleHtml} */}
          </div>
          <div className="aw-abstractWrap">
            {this.props.post.abstract}
          </div>
        </div>
        </React.Fragment>
      )
    }
  }