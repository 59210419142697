import React from 'react';

function ComingSoon(){

    return(
        <React.Fragment>
            <div style={{textAlign:'center',borderBottom: '1px solid'}}>
            <div>Coming soon</div>
            <div>... working on it ...</div>
            </div>
        </React.Fragment>
    );
}
export default ComingSoon;