import React from 'react';
import './Editor.css';
import Login from '../Login/Login';
import Overlay from '../Overlay/Overlay';
import PostMedia from '../Feed/PostMedia';
import Svg from '../Close/Close';
// import Switch from '../Switch/Switch';


class Editor extends React.Component {  
    // create states to pass to each sibling
    // https://www.youtube.com/watch?v=dyL99ACQfsM  
  
    constructor(props) {
        super(props);
        // let user = JSON.parse(sessionStorage.getItem('userData')) ? JSON.parse(sessionStorage.getItem('userData')) : {};
        
        let post = this.props.post ?
            this.props.post
        :
            {}
        ;
        
        let postf = this.props.post ?
            {
                posttype:this.props.post.posttype,
                pid:this.props.post.pid
            }
         :
             {posttype:'status'}
         ;

        let user = this.props.user ?
            this.props.user
        :
            {}
        ;
        this.state = {
            moreInput:false,
            // message:'',
            // newPost: this.props.newPost,
            user: user,
            post:post,
            postf:postf,
            confirm:false,
            // profile: this.props.profile,
            // post: {posttype:'status'}
            
        };
    }
  
    evalDescription = (event) =>{
        this.onInputChange(event);
        this.parseUrl();
    }

    onInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var tempPost = {...this.state.post}
        tempPost[name] = value;

        var tempPostf = {...this.state.postf}
        tempPostf[name] = value;

        this.setState({
            post: tempPost,
            postf: tempPostf,
            message : null
        })
    }   
    toggleMore = () =>{
        const { moreInput } = this.state;
        this.setState( { moreInput : !moreInput } );  
    }

    parseUrl = () => {
        // let post={...this.state.post,...this.props.post,...this.postAdded}
        // let postf={...this.state.postf,...this.postAdded}
        let {description} = this.state.postf;
let postf = this.state.postf;
        // if (this.timeoutKup !== null) {
        //     window.clearTimeout(this.timeoutKup);
        // }

        var url_regex = /\bhttps?:\/\/\S+/gi;
        if ((url_regex.test(description))) {
            // this.timeoutKup = window.setTimeout(() => {
                let linkArray = description.match(url_regex);
                let linkurl = linkArray[0];
                // console.log("linkurl === : ",linkurl);
                // console.log("post.linkurl: ",post.linkurl );

                if(linkurl === postf.linkurl){
                    return;
                }
                // console.log("2... update post.... ",post );

                var url = `http://jalinkup.com/api/api/post/parseurl.php?inurl=${linkurl}`;
                fetch(url)
                .then(response => response.json())
                .then(json => {
                    if(json.total > 0) {
                        // var tempPost = {...postf};
                        var tempPost = {};
                        tempPost["otitle"] = tempPost["otitle"] ? tempPost["otitle"] : json.data.title;
                        tempPost["photourl"] = tempPost["photourl"] ? tempPost["photourl"] : json.data.photourl;
                        tempPost["abstract"] = tempPost["abstract"] ? tempPost["abstract"] : json.data.abstract;
                        
                        tempPost["linkurl"] = json.data.linkurl;
                        tempPost["vidsrc"] = tempPost["vidsrc"] ? 
                            tempPost["vidsrc"] 
                        : 
                            json.data.vidsrc ? 
                                json.data.vidsrc 
                            : 
                                ''
                        ;
                        // tempPost["vidsrc"] = json.data.vidsrc ? json.data.vidsrc : '';
                        tempPost["provider"] = json.data.provider;
                        tempPost["posttype"] = json.data.posttype;

                        let post={...this.state.post,...tempPost}
                        let postf={...this.state.postf,...tempPost}
        
                        this.setState({
                            postf,
                            post
                        });

                        console.log("3... update tempPost.... ",tempPost );
                        return;
                    }
                })
            // }, 1000);
            // return;
        }
        console.log("no url inside yet");
        return;
      }



    handleUpdate = () => {
        // this.setState( { message : null} );  

        // console.log('this.state.post... ',this.state.post);
        // console.log('this.props.post... ',this.props.post);
        // console.log('this.props.user... ',this.props.user);
        // console.log('this.postAdded.... ',this.postAdded);

        
        // let post={...this.state.post,...this.props.post,...this.postAdded}
        // let post={...this.state.post,...this.postAdded}
        let post={...this.state.postf,...this.postAdded}
        let {evdate,posttype} = post;
        // console.log('initial post... ',post);
        // return null;

        if (evdate) {
            // var evdate_regex = /^\d{4}\-\d{2}\-\d{2}$/;
            var evdate_regex = /^\d{4}-\d{2}-\d{2}$/;
            if (!(evdate_regex.test(evdate))) {
                var today = new Date();
                let message = `"${evdate}" is not recognized. For example, May 17, ${today.getFullYear()} should be entered as ${today.getFullYear()}-05-17.`;
                this.setState( { message : message});  
                return;
            }
            if(posttype!=='event'){
                let message = `Please change "${posttype}" to "Event". If not at event, please remove "${evdate}".`;
                this.setState( { message : message});  
                return;
            }
            // post["posttype"] = 'event';
        }
        else if(posttype==='event'){
            this.setState( { message : 'Please enter event date or select another post type.'} );  
        }
        post["promoted"] = post.promoted ? '1' : '0';
        
        // let url = this.props.newPost ? 
        //     `http://localhost:81/jalinkup/api/api/post/create.php` 
        // : 
        //     `http://localhost:81/jalinkup/api/api/post/update.php`
        // ;            
        let url = this.props.newPost ? 
        `http://jalinkup.com/api/api/post/create.php` 
        : 
        `http://jalinkup.com/api/api/post/update.php`
        ;            
        console.log('url............... ',url);
        console.log('post.............. ',post);
        // console.log('post.............. ',post);

        // return;

        fetch(url, {
          method: 'post',
          body: JSON.stringify(post)
        })
        .then(response => response.json())
        .then(json => {
            if(json.total === 1){
                this.setState({ 
                    post : {...json.data},
                    message : json.data.message,
                    confirm : true,
                    // returnedPid: json.data.returned,                
                });
            }
            else{
                this.setState({ 
                    message : json.data.message
                });
            }
        })
        // .then(function (response) {
        //   return response.json()
        // })
        // .then(function (data) {
        // })
      }    
    
    render() {
        let {user,post,moreInput,message,confirm} = this.state;
        // let {photourl} = post;
        // let {photourl,evdate} = post;

        let profile = this.props.profile ? this.props.profile : {};
        var {jadmins,juid,username,userlevel} = profile ? profile: {};
        let isPageAdmin = (profile.jadmins && user.juid) ? profile.jadmins.includes(user.juid) : false;
        let isPageOwner = (user.juid === profile.juid) ? true : false;

        // Add if uselevel === community
        username = (isPageAdmin || isPageOwner || profile.userlevel==='l') ? profile.username : user.username;

        // this.postAdded ={username:username,jaccid:profile.juid}
        this.postAdded ={
            jaccid:profile.juid,
            jauthid:user.juid,
            type:'a',
        }




        return (
            <React.Fragment>
            {(!user || !user.loggedIn)? 



                <Login toggleMenu={this.props.close} />
                :
                (user && user.loggedIn && confirm)?
                <Overlay 
                // message= {text}
                title="Share"
                close={this.props.close}
                backgroundClose={true}
                theme="editor"
                > 
                    <div id="apiform" className="editor__post"> 
                        <div className="editor__post-header">
                            <div className="editor__post-close" onClick={this.props.close}><Svg color="green" size="15"/></div>
                        </div>
                        <div className="editor__post-body flex_divider">
                            {message}
                            {/* All done. Your post has been shared.*/}
                        </div>
                        <div className="editor__post-footer">
                            {/* Share post |  */}
                            <a target="_blank" href={`http://jalinkup.com/post/${post.pid}`}> View Post</a>
                        </div>
                    </div>
                </Overlay>
            // }
            // {user && user.loggedIn && (!post.pid || !this.props.newPost) &&
            :
              <React.Fragment>
                    <Overlay 
                    // message= {text}
                    title="Share"
                    close={this.props.close}
                    backgroundClose={false}
                    theme="editor"
                    > 
                    <form id="apiform" className="editor__post"> 
                        <div className="editor__post-header">
                            <div className="editor__post-close" onClick={this.props.close}><Svg color="green" size="15"/></div>
                            {this.props.newPost? 
                            <React.Fragment>
                            <span className="editor__post-username"> @ {username}
                                {/* <input onChange={this.onInputChange} value={username} className="noStyleInput" id="editor__post-input-username" name="username" placeholder="username" type="text"/> */}
                            </span>
                            
                            <div className="editor__post-posttype">
                                <select onChange={this.onInputChange} value={post.posttype} id="posttype" name="posttype">
                                    <option value="status">Status</option>
                                    <option value="event">Event</option>
                                    <option value="photo">Photo</option>
                                    <option value="video">Video</option>
                                    <option value="link">Link</option>
                                </select>
                                </div>
                                </React.Fragment>
                            :
                            <div className="editor__post-posttype">
                                {/* Edit {post.posttype} */}
                            </div>
                            }
                            {(post.description || post.otitle) ?
                                <div className="editor__post-post" onClick={this.handleUpdate}>
                                    {this.props.newPost? 'Share' : `Update ${post.posttype}` }
                                </div>
                            :
                                <div className="editor__post-post" style={{opacity:'0.5'}}>Share</div>
                            }
                        </div>
                        {message &&
                            <div className="editor__post-message">{message}</div>
                        }
                        <div className="editor__post-body flex_divider">
                            {/* <span className="editor__post-username"> @
                                <input onChange={this.onInputChange} value={username} className="noStyleInput" id="editor__post-input-username" name="username" placeholder="username" type="text"/>
                            </span> */}
                            <textarea className="editor__post-update textAreas autoExpand" onChange={this.evalDescription} id="magicShare" name="description" data-min-rows='2' placeholder="Share something with followers" value={post.description}></textarea>
                            <div className="editor__post-more-input">
                                {(post.posttype!=='status' || post.otitle || post.abstract || post.photourl) &&
                                <React.Fragment>
                                <div className="aw-mediaWrap editor__post-postMedia">
                                    <div className="editor__post-postMedia-overlay"></div>
                                    <PostMedia post={post} />
                                </div>
                                {/* <input className="editor__post-title noStyleInput autoExpand" onChange={this.onInputChange} id="otitle" name="otitle" placeholder="Add a title" value={post.otitle} />
                                <textarea className="editor__post-abstract noStyleInput autoExpand" onChange={this.onInputChange} id="abstract" name="abstract" placeholder="Add an abstract" value={post.abstract} />
                                <input onChange={this.onInputChange} value={post.photourl} className="noStyleInput input-photourl" id="editor__post-input-photourl" name="photourl" placeholder="photourl" type="text"/> */}

                                {/* <div className="editor__post-media">
                                    <div className="editor__post-photo">
                                        <div className="editor__post-photo-img">
                                            <img alt={post.title || post.photourl} className="photourl-img" src={post.photourl} />
                                        </div>
                                    </div>
                                    <div className="editor__post-title-abstract">
                                        <textarea className="editor__post-title noStyleInput autoExpand" onChange={this.onInputChange} id="otitle" name="otitle" placeholder="Add a title" defaultValue={post.otitle}></textarea>
                                        <textarea className="editor__post-abstract noStyleInput autoExpand" onChange={this.onInputChange} id="abstract" name="abstract" placeholder="Add an abstract" defaultValue={post.abstract}></textarea>
                                    </div>
                                </div> */}
                                </React.Fragment>
                                }  
                                {( moreInput ) &&
                                    <div className="editor__post-more-input">
                                        <small>Image tip: <a href="http://postimages.org" target="_blank">Upload an image to Postimages.org</a>, copy the "Direct Link",</small>
                                        <input onChange={this.onInputChange} value={post.photourl} className="noStyleInput input-photourl" id="editor__post-input-photourl" name="photourl" placeholder="Paste image URL/web address" type="text"/>
                                        <input className="editor__post-title noStyleInput autoExpand" onChange={this.onInputChange} id="otitle" name="otitle" placeholder="Add a title here" value={post.otitle} />
                                        <textarea className="editor__post-abstract noStyleInput autoExpand" onChange={this.onInputChange} id="abstract" name="abstract" placeholder="Add an abstract" value={post.abstract} />

                                        {/* <textarea className="editor__post-title noStyleInput autoExpand" onChange={this.onInputChange} id="otitle" name="otitle" placeholder="Add a title" defaultValue={post.otitle}></textarea>
                                        <textarea className="editor__post-abstract noStyleInput autoExpand" onChange={this.onInputChange} id="abstract" name="abstract" placeholder="Add an abstract" defaultValue={post.abstract}></textarea>
                                        <input onChange={this.onInputChange} value={post.photourl} className="noStyleInput input-photourl" id="editor__post-input-photourl" name="photourl" placeholder="photourl" type="text"/> */}
                                        <input className="editor__post-adurl noStyleInput" onChange={this.onInputChange} id="adurl" name="adurl" placeholder="Send users to this URL for more details." defaultValue={post.adurl} />
                                        <input className="editor__post-vidsrc noStyleInput" onChange={this.onInputChange} id="vidsrc" name="vidsrc" placeholder="Playable URL for videos/audios" value={post.vidsrc} />
                                        {/* <Switch label1 = {'promotion OFF'} label2 = {'promotion ON'} handleFeedChange={this.onInputChange} name={"promoted"} showFollowing={post.promoted}/> */}
                                        <input className="editor__post-linkurl noStyleInput" onChange={this.onInputChange} id="linkurl" name="linkurl" placeholder="Default URL extracted from input" value={post.linkurl} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="editor__post-footer">
                            {/* {(post.posttype==='event' || post.evdate || moreInput) && */}
                            <span>
                                <input onChange={this.onInputChange} value={post.evdate} className="noStyleInput" id="editor__post-input-evdate" name="evdate" placeholder="YYYY-MM-DD" type="text" length="10"/>
                            </span>
                             {/* } */}
                            <span className="flex_divider"></span>
                            <span onClick={this.toggleMore}><span style={{fontWeight:'600'}}>{moreInput? 'hide' : 'show'}</span> advanced options</span>
                        </div>

                    </form>
                    </Overlay>
            </React.Fragment>
            }
            </React.Fragment>
        )
    }
}
export default Editor;