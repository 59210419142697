import React from 'react';
import './Switch.css';
class Switch extends React.Component {
    render () {
        return(
            <div className="switchWrapper">
                <label>
                    {/* <div style={{textAlign:'center'}}>favourites</div> */}
                    <input ref="switch" name={this.props.name}
						checked={ this.props.showFollowing } 
						onChange={ this.props.handleFeedChange } 
						className="switch" type="checkbox" />
                    <div className= {`switchDesigner ${this.props.switchDesignName}`}>
                        <div></div>
                        <span className="switchLabel1">
                            {/* <div><i className="fa fa-lg fa-globe"></i></div> */}
                            {this.props.label1 || 'Public'}
                        </span>
                        <span className="switchLabel2">
                            {/* <div><i className="fa fa-lg fa-star"></i></div> */}
                            {this.props.label2 || 'Following'}
                        </span>
                    </div>
                </label>
            </div>
        );
    }
}
export default Switch;