import React from 'react';
import Editor from '../Editor/Editor';
import ShareVia from '../ShareVia/ShareVia';
import Overlay from '../Overlay/Overlay';
import Svg from '../Close/Close';
import PostMedia from './PostMedia';
// import HorizontalSlideCard from '../CommunitiesList/HorizontalSlideCard';
import './Feed.css';

class Feed extends React.Component {
    constructor(props) {
      super(props);

      // let isFollowing = (this.props.currentUser.following) ? this.props.currentUser.following.includes(this.props.profile.juid) : false;


      this.state = {
        posts: [],
        per: 5,
        page: 0,
        totalPages: 1,
        scrolling: false,
        showMenu: false,
        showMenuFor: {},
        isFollowing:this.props.isFollowing,
      }
    }
    
    togglePostMenu = (post) => {
      let{showMenu} = this.state;
      this.setState( 
        { showMenu : !showMenu },
        ()=>{
          this.setState( 
            { showMenuFor : post }
          )}
      );
    }

    componentWillReceiveProps(props) {
      this.setState({
        scrolling: true,
        posts: [],
        page: 0
      }, 
      () => {
        this.loadPosts();
      });
      this.scrollListener = window.addEventListener('scroll', (e) => {
        this.handleScroll(e)
      })
    }
    
    handleScroll = () => {
      const { scrolling, totalPages, page} = this.state
      if (scrolling) return
      if (totalPages <= page) return
      //var lastLi = document.querySelector('ul.posts > li:last-child')
      var lastLi = document.querySelector('.feedItems > .feedItem:last-child')
      var lastLiOffset = (lastLi) ? lastLi.offsetTop + lastLi.clientHeight:200;
      var pageOffset = window.pageYOffset + window.innerHeight
      var bottomOffset = 150
      if (pageOffset > lastLiOffset - bottomOffset) {
        this.loadMore()
      }
    }
  
    loadPosts = () => {
      var { per, page, posts } = this.state
      var {filter,pagetype,isFollowing} = this.props;
      let searchby = this.props.postsBy;

      // console.log('... state.isFollowing... ',this.state.isFollowing);
      // console.log('... props.isFollowing... ',this.props.isFollowing);

      this.feedIsPost = false;
      this.feedIsProfile = false;
      let endpoint = (searchby.length > 20) ? `following=${searchby}` : `isFollowing=${isFollowing}&profileid=${searchby.replace(/\W/g, '')}` ;
      
      filter = (filter === 'communities') ? pagetype : filter;

      if(filter==='feed' || filter==='events'){
        this.feedIsPost = true;
        var url = `http://jalinkup.com/api/api/post/${filter}.php?per=${per}&page=${page}&${endpoint}`; //production
      }
      // else if(filter==='communities'){
      //   this.feedIsProfile = true;
      //   let pagetype = this.props.pagetype;
      //   var url = `http://jalinkup.com/api/api/users/${filter}.php?page=${page}&communityType=${pagetype}&${endpoint}`;
      // }
      else{
        this.feedIsProfile = true;
        var url = `http://jalinkup.com/api/api/users/pages.php?pagetype=${filter}&per=10&page=${page}&${endpoint}`;
      }
      console.log('url... ',url);
      // alert(url);
      fetch(url)
        .then(response => response.json())
        .then(json => {
          this.totalResults = json.total;
          if(json.total > 0) {
            this.setState({
              posts: [...posts, ...json.data],
              scrolling: false,
              totalPages: page + 2,
            })
          }
          else{
            this.setState({
              scrolling: false,
              totalPages:json.total,
            })
          }      
      })
    }
  
    loadMore = () => {
      this.setState(prevState => ({
        page: prevState.page+1,
        scrolling: true,
      }), this.loadPosts)
    }
    
    render() {
      let loadStatus = (this.totalResults === 0) ?
        (this.state.page === 0) ?
          <LoadStausDisplay message={`No ${this.props.filter} found.`} /> 
          :
          <LoadStausDisplay message={`A it dat inno... no more ${this.props.filter}`} />             
      :
      (this.state.page > 0) ?
        <LoadStausDisplay didyouknow={true} message= {`Looking for more ${this.props.filter}...`}/>             
        :
        <LoadStausDisplay didyouknow={true} message= {`Looking for ${this.props.filter}...`}/>             
      ;


      // let isFollowing = (this.props.currentUser.following) ? this.props.currentUser.following.includes(this.props.profile.juid) : false;
      // console.log('... this.isFollowing render... ',this.isFollowing);
      // console.log('... let isFollowing... ',isFollowing);

      return (
        <React.Fragment>
          <div className="Xposts feedItems post-container">
            {(this.state.showMenu) &&
              <PostMenu 
                toggleMenu={this.togglePostMenu} 
                post={this.state.showMenuFor} 
                profile={this.props.profile} 
                user={this.props.currentUser}
                /> 
            }
            { this.state.posts.length > 0 &&
              this.state.posts.map( (post) => (
                <div key={post.username+post.pid+this.state.page} keyid={post.username+post.opid+this.state.page} id={post.username} className="feedItem aw-eachPost ">
                  {(this.feedIsPost) &&
                    <Post 
                      // userJuid={`${this.props.currentUser.juid}`} 
                      post = {post} 
                      togglePostMenu={this.togglePostMenu}
                      profile={this.props.profile} 
                      user={this.props.user}
                      />
                  }
                  {(this.feedIsProfile) &&
                    <Profile {...post} />
                  }
                </div>
              ))
            }
            {loadStatus}
            {/* {
              this.state.posts.length === 0 &&
              <LoadStausDisplay message='Sit tight while we look around...' />             
            }                 */}
          </div>
          {this.state.error &&
            <h3>{this.state.error}</h3>
          }
        </React.Fragment>
      )
    }
  }
  export default Feed;

  function LoadStausDisplay(props){
    return(
      <div className="loadStatusMsg">
        {props.message}
      </div>
    );
  }
  
function Profile(props){
  let {avatarurl,firstname,username} = props;
  if(avatarurl){
    avatarurl = (avatarurl.includes("user/")) ? 'http://jalinkup.com/'+avatarurl : avatarurl;
    var profileImg = (avatarurl) ?
      <div className="profileCard__img-wrap">
        <img className="profileCard__img" src={avatarurl} alt={username} />
      </div>
      :
      <div className="profileCard__img-wrap">
        <i className=" fa fa-map-marker"></i>
      </div>
    ;
  }

  return(
    <div className="profileCard__Feed clearfix">
      <div className="pad0 col-xs-12 profileCard__main flex-r">
        {/* {profileImg} */}
        <div className="pad0 profileCard__main-info flex-c w100p">
          <a href={`/${username}`}>
            <h4 className="profileCard__name">{firstname}
            <br /> <small>@{username} <span></span></small> 
            </h4>
          </a>
        </div>                
        {profileImg}
      </div>
    </div>
  );
}

  function Post(props){
      // check if post is a reaction... display top details
      let post = props.post;
      return (
        <React.Fragment>
          <CallPostMenu 
            togglePostMenu={props.togglePostMenu}
            post={props.post} 
          />
          <PostHead firstname={post.firstname} username={post.username} juid={post.juid} posttype={post.posttype} pid={post.pid} />
          <PostDescription description={post.description} />
          <div className="aw-mediaWrap">
            <PostMedia post={post} />
          </div>
          <div className="aw-reactions clearfix">
            {/* <span className="pull-left"> Like </span>        
            <span className="pull-left aw-ReactComment"> Comment </span>         */}
            <CallShareVia post={post} />
            <span className="pull-right aw-ReactDetails">
              <a  className="btn" target="_blank" rel="noopener noreferrer" href={`${post.adurl || post.linkurl || '/post/e6864055c529f0eb7a7e'}`}> Details</a> 
            </span>        
          </div>
        </React.Fragment>
      )
  }
  
  function CallPostMenu(props){
    let handleToggle = () => {
      const {post } = props
      props.togglePostMenu(post)
    }
    return (
      <React.Fragment>
      <span className="aw-callPostOptions" onClick={handleToggle}>
        <i className="fa fa-lg fa-ellipsis-h"></i>
      </span>
      </React.Fragment>
    )
  }
 
  class CallShareVia extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        showShare: false,
        // showMenuFor: {},
      }
    }
    toggleShareVia = () => {
      let{showShare} = this.state;
      console.log('share called...');
      this.setState( 
        { showShare : !showShare },
      );
    }

    render(){
    let post=this.props.post;
    return (
      <React.Fragment>
        {(this.state.showShare) &&
          <ShareVia urlToShare={'http://jalinkup.com/post/'+post.opid} toggleShareVia={this.toggleShareVia} post={post}/> 
        }
        <span onClick={this.toggleShareVia} className="pull-left">
          <span className="btn">Share</span>
        </span> 
      </React.Fragment>
    )
  }
}

  class PopOption extends React.Component {      
    render() {
      const post = this.props.post;
      return (
        <React.Fragment>
          {/* <div id={'awCreateBoxOverlay'+post.pid} className="awCreateBoxOverlay" onClick={this.props.toggleMenu}> </div> */}
          {/* <div id={'awCreateBoxOverlay'+post.pid} className="awCreateBoxOverlay"> </div>
          <div id={'awCreateBoxFixed'+post.pid} className="awCreateBoxFixed xyCenter">
            <div className="awCreateBoxWrap">
              <div className="awCreateBox">  */}
                <PostMenu 
                  toggleMenu={this.props.toggleMenu} 
                  post={post} 
                  profile={this.props.profile} 
                  user={this.props.user}
                  />
              {/* </div>
            </div>
          </div>     */}
        </React.Fragment>
      );
    }
  }
  
  class PostMenu extends React.Component {
    state = {
      menuDisplay: false,
    }
    toggleMenu = () => {
      const { menuDisplay } = this.state;
      this.setState( { menuDisplay : !menuDisplay } );
    }  
    render() {
      // let post = this.props.post;
      let{post,user,profile} = this.props;
      //check if juid = jaccid || juid is in jadmins before edit or delete

      let isPageAdmin = (post.jadmins && user.juid) ? post.jadmins.includes(user.juid) : false;
      let text = post.otitle || '';
      console.log('PostMenu=> profile... ',this.props.profile);
      console.log('PostMenu=> user...... ',this.props.user);
      console.log('PostMenu=> post...... ',this.props.post);
      return (
          <React.Fragment>
          {(this.state.menuDisplay) &&
            // <PostEditor toggleMenu={this.toggleMenu} post={post} />
            <Editor 
              post={post} 
              close={this.toggleMenu} 
              profile={this.props.profile} 
              user={this.props.user} 
              // newPost={false}
              />
          }  
                    
          {/* <div className="aw-optionsWrap">
            <div className="aw-optionsHeader">
              <span className="pull-left">This {post.posttype} seen {post.seencount} times</span>
              <span className="pull-right aw-optionClose" onClick={this.props.toggleMenu}>close</span>
            </div>
            <div className="aw-optionsBody"> */}
          {(!this.state.menuDisplay) &&
            <Overlay 
                message= {text}
                title={`This ${post.posttype} seen ${post.seencount} times`}
                close={this.props.toggleMenu}
                backgroundClose={false}
                theme="postOptions"
                > 

                  <div id="apiform" className="editor__post"> 
                      <div className="editor__post-header">
                          <div className="editor__post-close" onClick={this.props.toggleMenu}><Svg color="green" size="15"/></div>
                          {`This ${post.posttype} has been seen ${post.seencount} times`}
                      </div>
                      <div className="editor__post-body flex_divider">
                        {/* <div onClick="sharePost();" className="aw-postMenuItem">{'Share ' + post.posttype}</div>  */}
                        {/* <ShareVia urlToShare={'http://jalinkup.com/post/'+post.opid} toggleShareVia={this.toggleShareVia} post={post}/>  */}
                        {(user.juid === post.juid || isPageAdmin) && 
                        <React.Fragment>
                          <div onClick={this.toggleMenu} className="aw-postMenuItem">
                            {'Edit... ' + post.posttype}
                          </div>
                          <div onClick="confirmDelete();" className="aw-postMenuItem">
                            {'Delete... ' + post.posttype}
                          </div>
                        </React.Fragment>
                        }
                        {(post.provider) && 
                        <div className="aw-postMenuItem">
                          <a href={'/browse/via/'+post.provider}>
                            {'More from ' + post.provider}
                          </a> 
                        </div>
                        }
                      </div>
                      <div className="editor__post-footer">
                          {/* Share post |  */}
                          <a target="_blank" href={`http://jalinkup.com/post/${post.pid}`}> {'Go to ' + post.posttype}</a>
                      </div>
                  </div>
              </Overlay>
            }
            {/* </div>
          </div> */}
          </React.Fragment>
      );
    }
  }
  
  class PostHead extends React.Component{
    render(){
      // check if post is a reaction... display top details
      let userUrl = this.props.username ? this.props.username : this.props.juid;
      return (
        <div className="aw-postHead">
          <span className="aw-username">
            <a href={`/${userUrl}`}>{this.props.firstname || '@' + this.props.username}</a>
          </span>
          {/* <span> 
            <a target="_blank" rel="noopener noreferrer" href={'/post/'+this.props.pid}> {this.props.posttype}</a>
          </span>
          <span className="blah bla"> + Follow </span> */}
        </div>
      )
    }
  }
  
  class PostDescription extends React.Component{
    nl2br (str, isXhtml) {
      //  discuss at: http://locutus.io/php/nl2br/
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      return (str + '')
        .replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
    }
  
    render(){
      //get regEx for #s, urls and @s
      var description = this.props.description ? this.props.description :'';
      
      if(description){
        description = description.replace(/\bhttps?:\/\/\S+/gi, function(match,content) {const showLimit = 30; const matchLength = match.length;  const showUrl = (matchLength > showLimit) ? match.substr(0, showLimit-10)+'.....'+match.substr(matchLength-10, matchLength) : match ; return '<a className="urlCss" target="_blank" rel="noopener noreferrer" href="/ext?url='+match+'"><i className="fas fa fa-external-link-alt"></i>'+showUrl+'</a>'})          
        description = description.replace(/\B@+([a-zA-Z0-9_]+)/gi, ' <a className="mentionCss" href="/$1">@$1</a>');
        description = description.replace(/\B#+([a-zA-Z0-9_]+)/gi, ' <a className="hashtagCss" target="_blank" rel="noopener noreferrer" href="/browse/tags/$1">#$1</a>');
        description = this.nl2br(description,false);
        return (
          <div dangerouslySetInnerHTML={{ __html: description }} className="aw-postDescription">
          </div>
        )
    }
    else{
      return null;
    }
    }
  }