import React, {Component} from 'react';
// import Switch from '../Switch/Switch';
import './MainNavigation.css';

class MainNavigation extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="aw-MainNav">
                    <a href="/" className="aw-MainNav-item">
                        <div><i className=" fa fa-lg fa-home"></i></div>
                        <div>Home</div>                    
                    </a>
                    <a href="/?filter=events" className="aw-MainNav-item">
                        <div><i className="fa fa-lg fa-calendar"></i></div>
                        <div>Events</div>                    
                    </a>
                    <a href="/?filter=communities" className="aw-MainNav-item">
                        <div><i className="fa fa-lg fa-map-marker"></i></div>
                        <div>Communities</div>                    
                    </a>
                    <a href="/?filter=people" className="aw-MainNav-item">
                        <div><i className="fa fa-lg fa-users"></i></div>
                        <div>People</div>                    
                    </a>
                    {/* {(this.props.loggedIn) && 
                    <div className="aw-MainNav-switch">
                        <Switch label1 = {this.props.profile.username || 'WorldWide'} handleFeedChange={this.props.handleFeedChange} showFollowing={this.props.showFollowing} click={this.props.feedHandler} />
                    </div>
                    }                     */}
                    {(!this.props.loggedIn) && 
                    <div onClick={this.props.callPostEditor} className="aw-MainNav-item aw-MainNav-login">
                        <div>Login</div>
                        <div>Signin</div>
                    </div>
                    }
                </div>    
            </React.Fragment>
        );
    }
}
export default MainNavigation;