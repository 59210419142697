import React from 'react';
import './ShareVia.css';
import Overlay from '../Overlay/Overlay';
import Svg from '../Close/Close';


function ShareVia(props){
    let urlToShare = props.urlToShare ? props.urlToShare :'http://jalinkup.com';
    let post = props.post ? props.post : props.profile;
    let jid = post.opid || post.juid || '';
    let text = post.otitle || post.abstract || post.description || post.firstname || ''
    let image = post.photourl || post.avatarurl || '';
    let shareTo=[
        {name: 'WhatsApp', url: 'https://wa.me/?text='+text+' - '+urlToShare},  
        {name: 'JAlinkup', url: 'http://jalinkup.com/submit?jid='+jid+'&url='+urlToShare},  
        {name: 'Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u='+urlToShare},  
        {name: 'Twitter', url: 'https://twitter.com/intent/tweet?url='+urlToShare+'&text='+text},  
        {name: 'LinkedIn', url: 'http://www.linkedin.com/shareArticle?mini=true&url='+urlToShare},  
        {name: 'Pinterest', url: 'http://pinterest.com/pin/create/button/?url='+urlToShare+'&media='+image+'&description='+text},  
        {name: 'Google+', url: 'https://plus.google.com/share?url='+urlToShare},  
        {name: 'Tumblr', url: 'http://www.tumblr.com/share?v=3&u='+urlToShare+'&t='+text},  
    ];
    return(
        <React.Fragment>
            <Overlay 
                message= {text}
                title="Share"
                close={props.toggleShareVia}
                backgroundClose={true}
                theme="share"
                > 

                <div className="editor__post-header">
                    <div className="editor__post-close" onClick={props.toggleShareVia}><Svg color="green" size="15"/></div>
                    Share via...
                </div>


                {
                shareTo.map((social, index) => 
                    <ShareViaLinks key={social.name}
                        url={social.url}
                        name={social.name}
                        image={social.image}
                    />
                )
                }  
            </Overlay>
        </React.Fragment>
    );
}
export default ShareVia;

function ShareViaLinks(props){
    let{name,url,text,image} = props;
    return(
        <div className="dialog__list-items">
            <a className="shareVia__btn xbtn" href={url} target="_blank" rel="noopener noreferrer">{name}</a>
        </div>
    );
}