import React from 'react';

export default class GoogleAd extends React.Component {
    componentDidMount() {
      const installGoogleAds = () => {
        const elem = document.createElement("script");
        elem.src =
          "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        elem.async = true;
        elem.defer = true;
        document.body.insertBefore(elem, document.body.firstChild);
      };
      installGoogleAds();
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  
    render() {
      return (
        <React.Fragment>
<div  className="aw-sidebarItemWrap">

{/* <div> ad</div> */}
          <ins className='adsbygoogle'
               style={{ display: 'block',maxWidth:'100%',padding:'1px',height:'260px' }}
               data-ad-client='ca-pub-4723010099995588'
               data-ad-slot='2886383156'
               data-ad-format='auto' />

               </div>
        </React.Fragment>


            //  <ins className="adsbygoogle"
            //  style="display:block;max-width:100%;padding:1px;height:260px"
            //  data-ad-client="ca-pub-4723010099995588"
            //  data-ad-slot="2886383156">
            //  </ins>

      );
    }
  }