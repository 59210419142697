import React, { Component } from 'react';
import './MinorEvents.css';
import MinorEventsCard from './MinorEventsCard';

class MinorEvents extends Component {
  constructor(props){
    super(props);
    this.state = {
      allEvents: [],
    }
  }

  loadPosts = () => {
    let postsBy = this.props.postsBy;
    const per = 4;
    const page = 0;
    const url = `http://jalinkup.com/api/api/post/event.php?type=event&per=${per}&page=${page}&mention=${postsBy.replace(/\W/g, '')}`;
    // const url = `http://jalinkup.com/api/api/post/read.php?type=event&per=${per}&page=${page}&following=${postsBy}`;
    fetch(url)
    .then(response => response.json())
    .then((posts) => {
      this.daNewEvents = [...posts.data]
      this.setState({
        allEvents: [...posts.data],
      });
    });
  }; 

  componentWillReceiveProps(props) {
    // this.loadPosts();
    this.setState({
      // showFollowing: props.showFollowing,
      // postsBy: props.postsBy,
      allEvents: [],
    }, 
    () => {
      this.loadPosts();
    });

  }

  changeSlide(change) {
    if(change>0){
      var newEventsArray = this.moveToRight(this.state.allEvents,0,1);
    }
    else{
      var newEventsArray = this.moveToLeft(this.state.allEvents,-1,1);
    }
    this.setState({
      allEvents: newEventsArray,
    })
  }
  moveToLeft = (array,from,by) =>{
    var copyOfArray = [...array];
    var rightObjects = copyOfArray.splice(from,by);
    var newArray = [...rightObjects,...copyOfArray];
    return newArray;
  }

  moveToRight = (array,from,by) =>{
    var copyOfArray = [...array];
    var leftObjects = copyOfArray.splice(from,by);
    var newArray = [...copyOfArray,...leftObjects];
    return newArray;
  }

  render() {
    // var {allEvents,slides} = this.state;
    var {allEvents} = this.state;
    const numberOfSlides = allEvents.length;

    allEvents = (numberOfSlides > 3) ? this.moveToLeft(allEvents,-2,2) : this.moveToLeft(allEvents,-1,1);
    var activeIndex = (numberOfSlides > 2) ? Math.floor(numberOfSlides/2) : 0;

    let message = (this.state.allEvents[0]) ? this.state.allEvents[0].message : '' ;
    if(message || !this.props.postsBy) return null
  
    let controls = (numberOfSlides > 1) ? 
    <React.Fragment>
      <div className="aw-btn-slide aw-btn-slide-next" onClick={() => this.changeSlide(1)}>
        <div>&#10095;</div>
      </div>
      <div className="aw-btn-slide aw-btn-slide-previous" onClick={() => this.changeSlide(-1)}>
        <div>&#10094;</div>
      </div>
    </React.Fragment>
    : '';
    return (
      <React.Fragment>
      <div className="aw-slide-wrapper">
        {controls}
        <div className="Slide">
          <div className="col">
            <div className={` cardSlideView alignFor${numberOfSlides} cards-slider active-slide-${activeIndex}`}>
              <div className="cards-slider-wrapper" style={{'transform': `translateX(-${activeIndex*(100/allEvents.length)}%)`}}>
                {allEvents.map((property, index) => <MinorEventsCard activeIndex = {activeIndex} indexData = {index} key={property.pid} property={property} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}
export default MinorEvents;