import React from 'react';
import './DrawerToggleButton.css';

const drawerToggleButton = props => (
    <React.Fragment>
        <svg className="toggle-button" width="30" height="30" onClick={props.click}>
            <path d="M0,5 30,5" stroke="#000" strokeWidth="3"/>
            <path d="M0,14 30,14" stroke="green" strokeWidth="3"/>
            <path d="M0,23 30,23" stroke="yellow" strokeWidth="5"/>
        </svg>
    </React.Fragment>
);
export default drawerToggleButton;