import React, {Component} from 'react';
// import {Redirect} from 'react-router-dom';
// import {PostData} from '../../services/PostData';
import ComingSoon from '../ComingSoon/ComingSoon';
import './Login.css';
import Overlay from '../Overlay/Overlay';
import Svg from '../Close/Close';

class Login extends Component {
  constructor(){
    super();
    this.state = {
      username: '',
      password: '',
      redirectToReferrer: false
    };
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  PostData = (type, userData) => {
    // let url = `http://localhost:81/api/api/users/login.php`;
    let url = `http://jalinkup.com/api/api/users/login.php`;

    return new Promise((resolve, reject) =>{
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(userData)
      })
      .then((response) => response.json())
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  login() {
    if(this.state.username && this.state.password){
      this.PostData('login',this.state)
      .then((result) => {
        let responseJson = result;
        if(responseJson.id){ 
          responseJson['loggedIn']=true;
         sessionStorage.setItem('userData',JSON.stringify(responseJson));
         this.setState({redirectToReferrer: true});
         window.location.reload();
        }
        console.log('userData:... ',responseJson);
      });
    }
    else{
      console.log('retry');
      // prompt to retry
    }
   }

  onChange(e){
    this.setState({[e.target.name]:e.target.value});
  }
  render() {

    //  if (this.state.redirectToReferrer) {
    //   return (<Redirect to={'/home'}/>)
    // }
   
    if(sessionStorage.getItem('userData')){
      // return (<Redirect to={'/home'}/>)
      var userData = JSON.parse(sessionStorage.getItem('userData'));
      console.log('userData.. testtttttt .... ',userData.firstname);
    }

     return (
       <React.Fragment>

        <Overlay 
          // message= {text}
          title="Please login to continue..."
          close={this.props.toggleMenu}
          backgroundClose={true}
          theme="login"
  >
                    <div id="apiform" className="editor__post"> 
                        <div className="editor__post-header">
                            <div className="editor__post-close" onClick={this.props.toggleMenu}><Svg color="green" size="15"/></div>
                        </div>

       {/* <div className="loginBody" id="Body">
         <div className="loginInner"> */}
          {/* <div>
            Using social profile
          </div> */}
          
          <div className="editor__post-body flex_divider">
            <ComingSoon />
            <div>
              <input defaultValue="" type="text" name="username" placeholder="Username" onChange={this.onChange}/>
              <input defaultValue="" type="password" name="password"  placeholder="Password" onChange={this.onChange}/>
            </div>
            <div>
              <input type="submit" className="btn" value="Login" onClick={this.login}/>
              <a className="btn" href="/signup">Signup</a>
            </div>
          </div>
          <div className="editor__post-footer">
            <a href="/forgot_pass.php" className="btn-link">Forgot password?</a>
          </div>        
        {/* </div>
      </div> */}
</div>
</Overlay>

          </React.Fragment>
    );
  }
}

export default Login;