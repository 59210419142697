import React from 'react';
import GoogleAd from '../Ads/GoogleAd';
import './Sidebar.css';

  function Sidebar(props){
    // let {firstname,username} = props.currentUser;
    return(
      <React.Fragment>
        <SubscribeForm currentUser={props.currentUser}/>
        <GoogleAd />
        <SidebarItem />
      </React.Fragment>

    );

  }
  export default Sidebar;

  class SidebarItem extends React.Component {
    render() {
      let SIDEBARITEMS = [
        {title: 'Do You Love Jamaica?', image: 'http://jalinkup.com/user/5245606edafd21b4/avatar', prompt: 'Represent Jamaica home and abroad', url: 'http://rep876.com', callbutton: 'Visit Rep876.com'},
        {title: 'Enter To Win', image: 'http://jalinkup.com/user/503572e0488ad999/avatar', prompt: 'Need More Details?', url: 'http://jalinkup.com/503572e0488ad999', callbutton: 'Click Here'},
        {title: 'Find Your Community', image: 'http://s09.flagcounter.com/count2/jbc1/bg_ffffff/txt_1B5424/border_ffffff/columns_4/maxflags_24/viewers_Top+countries/labels_0/pageviews_0/flags_0/percent_0/', prompt: '', url: '/?filter=communities', callbutton: 'See full list'}  
      ]; 
  
      const rows = [];
      SIDEBARITEMS.forEach((item) => {
        const title = item.title ? <h4 className="aw-sidebarItemTitle">{item.title}</h4> : '';
        const image = item.image ? <img src={item.image} alt={title} border="0"/> : '';
        const prompt = item.prompt ? <p>{item.prompt}</p> : '';  
        const callbutton = item.callbutton ? <div className="aw-sidebarItemBtn btn btn-success">{item.callbutton}</div> : '';        
        
        rows.push(
          <div className="aw-sidebarItemWrap" key={item.title}>
              <a className="aw-sidebarItemUrl" href={item.url} target="_blank" rel="noopener noreferrer">
                {title}
                {image}
                {prompt}
                {callbutton}    
              </a>
          </div>  
        );
      });
      return (
        <React.Fragment>{rows}</React.Fragment>
      );
    }
  }
  function SubscribeForm(props) {
    let {firstname,username,email} = props.currentUser;
    return (
    <div className="aw-sidebarItemWrap aw-subscribe">
      <h4 className="aw-sidebarItemTitle">Hi {firstname || username || ' there'}...</h4>
      <p>to get free tickets and special offers</p>
      <div id="form_integration" className="mobile-sbscrptnform">
      <form style={{overflow: 'hidden'}} name="subscriptionFrm_21138" id="subscriptionFrm_21138" className="Xform-horizontal xform-group" action="https://fantasticfew.sendlane.com/form/21138" method="post">
          <input type="hidden" name="form_id" id="form_id" value="21138" />
          {/* Echo error message */}
  
          <div className="form-group" id="form_field_3 ">
              <div className="padding-a0 col-xs-12 subscrptn-btn">
                  <input placeholder="Enter your email address" defaultValue={email} className="jform-control" id="form_field[3]" name="form_field[3]" type="text" />													
              </div>
          </div>
          <div className="form-group" id="form_field_1 ">
              <div className="padding-a0 col-xs-12 subscrptn-btn">
                <input placeholder="Enter your first name" defaultValue={firstname || username} className="jform-control" id="form_field[1]" name="form_field[1]" type="text" />													
              </div>
          </div>
          <div className="form-group" id="form_field_4 " style={{marginBottom:0}}>
              <div className="padding-a0 col-xs-12 subscrptn-btn">
                  <div className="ml54-per">
                    <input style={{height:'50px'}} name="subscribe" className="btn xbtn-md btn-success btn-block" type="submit" value="Submit" />
                  </div>													
              </div>
          </div>
          <div id="form_field_164 ">
              <div className="padding-a0 col-xs-12 subscrptn-btn">
                <input className="jform-control" id="form_field[164]" name="form_field[164]" type="hidden" />													
              </div>
          </div>
      </form>
  </div>
  
  </div>
    );
  }