import React, { Component } from 'react';

import Toolbar from './components/Toolbar/Toolbar';
import HeaderStrip from './components/Toolbar/HeaderStrip';
import ProfileCard from './components/ProfileCard/ProfileCard';
import MinorEvents from './components/MinorEvents/MinorEvents';
import MainEvents from './components/MainEvents/MainEvents';
import Feed from './components/Feed/Feed';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';

class App extends Component {
  constructor(props) {
    super(props);
    if(sessionStorage.getItem('userData')){
      this.userData = JSON.parse(sessionStorage.getItem('userData'));
      this.userFollows = this.userData.following;
    }
    var pathArray = window.location.pathname.split( '/' );
    let browser = new URLSearchParams(window.location.search);
    this.profileUsername = browser.get('u') || pathArray[1] || '';
    this.filter = browser.get('filter') || pathArray[2] || 'feed';

    this.state = {
      profile: [],
      showFollowing: false,
    };
  }

  handleFeedChange = () => {
    const { showFollowing } = this.state;
    this.setState({
      showFollowing : !showFollowing
    }
    ,
    () => {
      this.userData['showFollowing']=this.state.showFollowing;
      sessionStorage.setItem('userData',JSON.stringify(this.userData));
    }
    );
  }

  componentWillMount() {
    this.profile(this.profileUsername);
  }
  
  profile = (username) => {
    const url = `http://jalinkup.com/api/api/users/profile.php?username=${username}`;
    fetch(url)
    .then(response => response.json())
    .then((user) => {
      this.landedPage = user.data;
      if(user.total){
        this.setState({
          profile: user.data,
        });
      }
      else{
        this.setState({
          profile: [],
        });
      }
    });
  };
  
  render() {
    let {filter} = this;
    let bgClass = (this.state.profile.bgurl && this.state.profile.userlevel !== 'l') ? 'hasBg' : 'noBg' ;

    var background = this.state.profile.bgurl ? 
      this.state.profile.bgurl 
      : 
      (filter==='feed' && !this.profileUsername) ? 
        'https://i.postimg.cc/C5sZT9t9/jalinkup-background.jpg'
      : 
        '' 
      ;   


    let loggedIn = (this.userData) ? this.userData.loggedIn : false;
    let currentUser = (this.userData) ? this.userData : [];
    
    var postsBy = (this.state.showFollowing) ? 
      (this.userData) ? 
        this.userData.following 
      : 
        '' 
    : 
      (this.state.profile.juid) ? 
        `'${this.state.profile.juid}'` 
      : 
        ''
    ;
    let isFollowing = (currentUser.following) ? 
      currentUser.following.includes(this.state.profile.juid) 
    : 
      ''
    ;

    return (
      <div className="app__wrap" style={{height: '100%', overflow:'hidden'}}>
        <Toolbar 
          filter={filter}
          currentUser={currentUser}
          profile={this.state.profile}
          loggedIn = {loggedIn}
          handleFeedChange={this.handleFeedChange} 
          showFollowing={this.state.showFollowing} 
          drawerClickHandler={this.drawerToggleClickHandler} 
          />
        <HeaderStrip 
          filter={filter}
          profile={this.state.profile}
          postsBy={postsBy}
          />
        <div className="mainContentWrap">
          <main className="main clearfix" id="main">
            <div className={`aw-body-img-wrap ${bgClass}`} style={{background: 'url(' + background + ')  no-repeat center',backgroundSize: ' 100% auto'}}>
              {/* <img onError={(e) => {e.target.parentNode.style = 'display:none;';}} style={{display:'none'}} src={background} alt="pic" /> */}
              {(this.filter === 'feed') &&
                <MainEvents 
                  postsBy={postsBy}
                  showFollowing={this.state.showFollowing}            
                  />
              }
              {/* or load profile card */}
            </div>
            <div className={`aw-body-content-wrap clearfix ${bgClass}`}>
              <div className="aw-main-content-wrap clearfix">
                <div className="aw-content-top-center">
                  {(!this.state.showFollowing) &&
                    <ProfileCard 
                      filter={filter}
                      profile={this.state.profile}
                      currentUser={currentUser}
                    /> 
                  }
                </div>
                <div className="col-sm-8 aw-content-mid-center clearfix">
                  {(this.filter === 'feed') &&
                    <MinorEvents 
                      postsBy={postsBy}
                      showFollowing={this.state.showFollowing} 
                    /> 
                  }
                  <Feed 
                    isFollowing={isFollowing}
                    currentUser={currentUser}
                    profile={this.state.profile}            
                    filter={this.filter}
                    postsBy={postsBy}
                    showFollowing={this.state.showFollowing} 
                    pagetype={this.state.profile.pagetype}
                  />
                </div>
                <div className="col-sm-4 aw-content-mid-right clearfix">
                  <Sidebar 
                    currentUser={currentUser}                    
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
export default App;